/**
Author - Brijesh Pandey
Git - https://github.com/Brijesh-Pandey
**/

import React, {useEffect} from "react";
import Datafeed from "./src/datafeed";
import Cookie, { getQueryParams, removeAuthFromHref } from "./src/helpers"
import TradingView from "./charting_library/charting_library.standalone";

const TradingViewChart = () => {

useEffect(() => {
const script = document.createElement("script");
script.type = "text/jsx";
script.src = "../public/charting_library/charting_library.js";

const timeInterval = window.localStorage.getItem("tradingview.chart.lastUsedTimeBasedResolution");

const fullURL = window.location.href;
const queryParams = getQueryParams(fullURL);

  console.log(queryParams, "from charts");

if(queryParams.auth){
  const storedCookie = Cookie.getCookie("wl_authorization");
  const newCookie = queryParams.auth;
  if (String(storedCookie) !== String(newCookie)) {
    Cookie.setCookie("wl_authorization", newCookie);
  }
  window.location.href = removeAuthFromHref(window.location.href);
}
const localSavedChart = window.localStorage.getItem("savedChart");

document.head.appendChild(script);
window.tvWidget = new TradingView.widget({
  symbol: queryParams ? `${queryParams.ts}:${queryParams.token}:${queryParams.en}:${queryParams.insType ?? ""}` : 'SBIN-EQ',
  interval: timeInterval ? timeInterval : '1D',
  fullscreen: true,
  container: 'tv_chart_container',
  locale: 'en',
  datafeed: Datafeed,
  debug: false,
  library_path: "../charting_library/",
  timezone: 'Asia/Kolkata',
  disabled_features: ["header_symbol_search", "header_fullscreen_button", "header_screenshot", "header_settings", "symbol_search_hot_key", "header_compare", "header_saveload"],
  enabled_features: ["use_localstorage_for_settings", "save_chart_properties_to_local_storage", "request_only_visible_range_on_reset"],
  hide_side_toolbar: true,
  auto_save_delay: 5,
  load_last_chart: true,
  saved_data: localSavedChart ? JSON.parse(localSavedChart) : null,

  time_frames:[
    { text: "5Y", resolution: "1W", description: "5 Years", title: "5Y" },
    { text: "1Y", resolution: "1W", description: "1 Year", title: "1Y" },
    { text: "6M", resolution: "1D", description: "6 Months", title: "6M" },
    { text: "1M", resolution: "1D", description: "1 Month", title: "1M" },
    { text: "1D", resolution: "5", description: "1 Day", title: "1D" },
    { text: "720", resolution: "5", description: "12 Hours", title: "12H" },
    { text: "360", resolution: "1", description: "6 Hour", title: "6H" }
],
});

  window.tvWidget.subscribe('onAutoSaveNeeded', () => {window.tvWidget.save(chartState => window.localStorage.setItem("savedChart", JSON.stringify(chartState)))})

return () => script.remove();
}, []);

return <div id="tv_chart_container"></div>;
};

export default TradingViewChart;