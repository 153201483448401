//
// Author - Brijesh Pandey
// Git - https://github.com/Brijesh-Pandey
//

import Cookie from "./helpers";

function checkEnvironment() {
  const origin = window.location.origin;

  if (origin === 'https://charts.wealthy.in') {
    return true;
  } else {
    return false;
  }
}

const isProd = checkEnvironment();


const baseURL = isProd ? "https://api.wealthy.in/broking" : "https://api.wealthydev.in/broking";
const hasAuthCookie = Cookie.getCookie("wl_authorization");

const prodToken = hasAuthCookie ? hasAuthCookie : "7dfaf86c-5bf1-45a4-8777-2103a1dfca61:KhDWgG2Kg2t1hb1m4VfySMjBa";
const devToken = hasAuthCookie ? hasAuthCookie : "30ed49c9-a0de-4d16-860e-5591f86d4e53:ANvKLqnKH76IMclEWStPInkUa";

let data = null;

let receivedData = null;
const channelToSubscription = new Map();

let initBar = {
  open: Number.NEGATIVE_INFINITY,
  high: Number.NEGATIVE_INFINITY,
  low: Number.POSITIVE_INFINITY
};

let prevEpoch = 0;

const getData = async (symbol, subscriberUID, unsubscribe = false) => {
  try {
    var myHeaders = new Headers();
    myHeaders.append("Authorization", isProd ? prodToken : devToken);
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("X-APP-VERSION", "broking-web");

    var requestOptions = {
      method: 'GET',
      headers: myHeaders,
      redirect: 'follow'
    };

    const res = await fetch(`${baseURL}/api/v0/auth/ws-url/?source=WEB`, requestOptions)
    data = await res.json();

    const {
      name,
      ticker,
      exchange
    } = symbol

    if (data) {
      const socketBaseUrl = isProd ? "wss://trade.wealthy.in" : "ws://noren.wealthydev.in"
      const connectionString = `${socketBaseUrl}${data.base_url}`;
      const authorizationBody = {...data.body}

      const webSocket = new WebSocket(connectionString);

      webSocket.onopen = (event) => {
        webSocket.send(JSON.stringify(authorizationBody));
      };

      webSocket.onmessage = (event) => {
        receivedData = JSON.parse(event.data);
        const subscriptionStockPayload = {
          t: "t",
          k: subscriberUID ??  "NSE|3045"
      }
        if (receivedData.t === "ck") {
          webSocket.send(JSON.stringify(subscriptionStockPayload))
        }

        const currentDate = new Date();

        let epochTimeInMinutes = Math.floor(currentDate.getTime() / (60 * 1000));
        epochTimeInMinutes *= 60000

        if (epochTimeInMinutes > prevEpoch) {
          if(receivedData?.lp) {
            initBar = {
              time: receivedData.ft,
              open: receivedData.lp,
              high: receivedData.lp,
              low: receivedData.lp,
              close: receivedData.lp
            }
            prevEpoch = epochTimeInMinutes
          }
        } else {
          if(receivedData?.lp) {
            if(initBar.open === Number.NEGATIVE_INFINITY) {
              initBar.open = receivedData.lp
            }
            initBar.time = epochTimeInMinutes
            initBar.high = Math.max(receivedData.lp, initBar.high)
            initBar.low = Math.min(receivedData.lp, initBar.low)
            initBar.close = receivedData.lp
          }
        }

        if(receivedData?.lp) {
          initBar.time = epochTimeInMinutes
          initBar.close = receivedData.lp
        }

        if(initBar?.open !== Number.NEGATIVE_INFINITY) {
          const channelString = subscriberUID;
          const subscriptionItem = channelToSubscription.get(channelString);
          subscriptionItem.lastDailyBar = initBar;
          subscriptionItem.handlers.forEach(handler => handler.callback(initBar));
        }
      };
    }
  } catch (error) {
    console.error(error);
  }
}

export function subscribeOnStream(
    symbolInfo,
    resolution,
    onRealtimeCallback,
    subscriberUID,
    onResetCacheNeededCallback,
    lastDailyBar
)
{
  getData(symbolInfo, subscriberUID)
  console.log(subscriberUID, "from subscribe on stream function")

    const parsedSymbol = symbolInfo.name;
    const channelString = subscriberUID;
    const handler = {
        id: subscriberUID,
        callback: onRealtimeCallback
    };

  let subscriptionItem = channelToSubscription.get(channelString);

    if (subscriptionItem) {
        subscriptionItem.handlers.push(handler);
        return;
    }
    subscriptionItem = {
        subscriberUID,
        resolution,
        lastDailyBar,
        handlers: [handler],
    };
    channelToSubscription.set(channelString, subscriptionItem);
};

export function unsubscribeFromStream(subscriberUID) {
  for (const channelString of channelToSubscription.keys()) {
    const subscriptionItem = channelToSubscription.get(channelString);
    const handlerIndex = subscriptionItem.handlers
      .findIndex(handler => handler.id === channelString);


    if (handlerIndex !== -1) {
        // Remove from handlers
      subscriptionItem.handlers.splice(handlerIndex, 1);

      if (subscriptionItem.handlers.length === 0) {
          console.log(subscriptionItem.handlers, channelString, channelToSubscription, "from unsub method")
            // TODO: Write unsubscribe logic here for kambala socket send channel string to unsubscribe
            channelToSubscription.delete(channelString);
            break;
        }
    }
}
}




// import Cookie from "./helpers";

// function checkEnvironment() {
//   const origin = window.location.origin;
//   return origin === 'https://charts.wealthy.in';
// }

// const isProd = checkEnvironment();
// const baseURL = isProd ? "https://api.wealthy.in/broking" : "https://api.wealthydev.in/broking";
// const hasAuthCookie = Cookie.getCookie("wl_authorization");

// const prodToken = hasAuthCookie ? hasAuthCookie : "7dfaf86c-5bf1-45a4-8777-2103a1dfca61:KhDWgG2Kg2t1hb1m4VfySMjBa";
// const devToken = hasAuthCookie ? hasAuthCookie : "30ed49c9-a0de-4d16-860e-5591f86d4e53:ANvKLqnKH76IMclEWStPInkUa";

// let data = null;
// let receivedData = null;
// const channelToSubscription = new Map();
// let webSocket = null;
// let isSocketConnected = false;

// let initBar = {
//   open: Number.NEGATIVE_INFINITY,
//   high: Number.NEGATIVE_INFINITY,
//   low: Number.POSITIVE_INFINITY
// };

// let prevEpoch = 0;

// const connectWebSocket = async () => {
//   try {
//     var myHeaders = new Headers();
//     myHeaders.append("Authorization", isProd ? prodToken : devToken);
//     myHeaders.append("Content-Type", "application/json");
//     myHeaders.append("X-APP-VERSION", "broking-web");

//     var requestOptions = {
//       method: 'GET',
//       headers: myHeaders,
//       redirect: 'follow'
//     };

//     const res = await fetch(`${baseURL}/api/v0/auth/ws-url/?source=WEB`, requestOptions);
//     data = await res.json();

//     if (data) {
//       const socketBaseUrl = isProd ? "wss://trade.wealthy.in" : "wss://noren.wealthydev.in";
//       const connectionString = `${socketBaseUrl}${data.base_url}`;
//       const authorizationBody = { ...data.body };

//       webSocket = new WebSocket(connectionString);

//       webSocket.onopen = (event) => {
//         webSocket.send(JSON.stringify(authorizationBody));
//         isSocketConnected = true;
//       };

//       return { webSocket, isSocketConnected };
//       //   receivedData = JSON.parse(event.data);

//       //   if (receivedData.t === "ck") {
//       //     for (const [channelString, subscriptionItem] of channelToSubscription) {
//       //       const subscriptionStockPayload = {
//       //         t: "t",
//       //         k: subscriptionItem.subscriberUID ?? "NSE|3045"
//       //       };
//       //       webSocket.send(JSON.stringify(subscriptionStockPayload));
//       //     }
//       //   }

//       //   const currentDate = new Date();
//       //   let epochTimeInMinutes = Math.floor(currentDate.getTime() / (60 * 1000));
//       //   epochTimeInMinutes *= 60000;

//       //   if (epochTimeInMinutes > prevEpoch) {
//       //     if (receivedData?.lp) {
//       //       initBar = {
//       //         time: receivedData.ft,
//       //         open: receivedData.lp,
//       //         high: receivedData.lp,
//       //         low: receivedData.lp,
//       //         close: receivedData.lp
//       //       };
//       //       prevEpoch = epochTimeInMinutes;
//       //     }
//       //   } else {
//       //     if (receivedData?.lp) {
//       //       if (initBar.open === Number.NEGATIVE_INFINITY) {
//       //         initBar.open = receivedData.lp;
//       //       }
//       //       initBar.time = epochTimeInMinutes;
//       //       initBar.high = Math.max(receivedData.lp, initBar.high);
//       //       initBar.low = Math.min(receivedData.lp, initBar.low);
//       //       initBar.close = receivedData.lp;
//       //     }
//       //   }

//       //   if (initBar?.open !== Number.NEGATIVE_INFINITY) {
//       //     const channelString = `0~${receivedData.exchange}~${receivedData.name}`;
//       //     const subscriptionItem = channelToSubscription.get(channelString);
//       //     if (subscriptionItem) {
//       //       subscriptionItem.lastDailyBar = initBar;
//       //       subscriptionItem.handlers.forEach(handler => handler.callback(initBar));
//       //     }
//       //   }
//       // };
//     }
//   } catch (error) {
//     console.error(error);
//   }
// };

// const getData = async (symbol, subscriberUID) => {
//   if (webSocket?.readyState !== 1 || !isSocketConnected) {
//     const { webSocket, isSocketConnected } = await connectWebSocket();

//     console.log(webSocket, isSocketConnected)

//     if (isSocketConnected) {
//       const subscriptionStockPayload = {
//         t: "t",
//         k: subscriberUID ?? "NSE|3045"
//       };
//       webSocket.send(JSON.stringify(subscriptionStockPayload));

//       webSocket.onmessage = (event) => {
//         receivedData = JSON.parse(event.data);

//         if (receivedData.t === "ck") {
//           for (const [channelString, subscriptionItem] of channelToSubscription) {
//             const subscriptionStockPayload = {
//               t: "t",
//               k: subscriptionItem.subscriberUID ?? "NSE|3045"
//             };
//             webSocket.send(JSON.stringify(subscriptionStockPayload));
//           }
//         }

//         const currentDate = new Date();
//         let epochTimeInMinutes = Math.floor(currentDate.getTime() / (60 * 1000));
//         epochTimeInMinutes *= 60000;

//         if (epochTimeInMinutes > prevEpoch) {
//           if (receivedData?.lp) {
//             initBar = {
//               time: receivedData.ft,
//               open: receivedData.lp,
//               high: receivedData.lp,
//               low: receivedData.lp,
//               close: receivedData.lp
//             };
//             prevEpoch = epochTimeInMinutes;
//           }
//         } else {
//           if (receivedData?.lp) {
//             if (initBar.open === Number.NEGATIVE_INFINITY) {
//               initBar.open = receivedData.lp;
//             }
//             initBar.time = epochTimeInMinutes;
//             initBar.high = Math.max(receivedData.lp, initBar.high);
//             initBar.low = Math.min(receivedData.lp, initBar.low);
//             initBar.close = receivedData.lp;
//           }
//         }

//         if (initBar?.open !== Number.NEGATIVE_INFINITY) {
//           const channelString = `0~${receivedData.exchange}~${receivedData.name}`;
//           const subscriptionItem = channelToSubscription.get(channelString);
//           if (subscriptionItem) {
//             subscriptionItem.lastDailyBar = initBar;
//             subscriptionItem.handlers.forEach(handler => handler.callback(initBar));
//           }
//         }
//       };
//     }
//   }
// };

// export function subscribeOnStream(
//   symbolInfo,
//   resolution,
//   onRealtimeCallback,
//   subscriberUID,
//   onResetCacheNeededCallback,
//   lastDailyBar
// ) {
//   getData(symbolInfo, subscriberUID);
//   console.log(subscriberUID, "from subscribe on stream function");

//   const channelString = subscriberUID;
//   const handler = {
//     id: subscriberUID,
//     callback: onRealtimeCallback
//   };

//   let subscriptionItem = channelToSubscription.get(channelString);

//   if (subscriptionItem) {
//     subscriptionItem.handlers.push(handler);
//     return;
//   }
//   subscriptionItem = {
//     subscriberUID,
//     resolution,
//     lastDailyBar,
//     handlers: [handler],
//   };
//   channelToSubscription.set(channelString, subscriptionItem);
// };

// export function unsubscribeFromStream(subscriberUID) {
//   for (const channelString of channelToSubscription.keys()) {
//     const subscriptionItem = channelToSubscription.get(channelString);
//     const handlerIndex = subscriptionItem.handlers.findIndex(handler => handler.id === channelString);

//     if (handlerIndex !== -1) {
//       subscriptionItem.handlers.splice(handlerIndex, 1);

//       if (subscriptionItem.handlers.length === 0) {
//         console.log(subscriptionItem.handlers, channelString, channelToSubscription, "from unsub method");
//         const unsubscribePayload = {
//           t: "u",
//           k: channelString ?? "NSE|3045"
//         };
//         if (webSocket && webSocket.readyState === 1) {
//           webSocket.send(JSON.stringify(unsubscribePayload));
//           channelToSubscription.delete(channelString);
//           break;
//         }
//       }
//     }
//   }
// }
